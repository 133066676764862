import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { Button, Columns, Container, Element, Form, Heading, Section } from 'react-bulma-components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormField, { HookFormWrapper } from '../../components/form/form-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useSession } from '../../stores/session'

const SavPage = () => {
  const [sessionState, sessionActions] = useSession()
  const { control, handleSubmit } = useForm({
    shouldUseNativeValidation: true
  })
  const onSubmit = data => {
    sessionActions.sendSavContact(data)
    //actions.login(data)
  }

  const request = React.useMemo(() => {
    sessionState.requests && sessionState.currentOrganization && sessionState.requests[`post /organizations/${sessionState.currentOrganization._id}/sav-contact`]
  }, [sessionState.requests])

  React.useEffect(() => () => {
    sessionActions.clearRequest({
      key: `post /organizations/${sessionState.currentOrganization._id}/sav-contact`
    })
  }, [])

  const { t } = useTranslation()
  return(
    <Layout pro>
      
      {
        request && request.status === 'success' ?
          <Section size='medium'>
            <Container>
              <Columns centered className='has-border-right' breakpoint='desktop'>
                <Columns.Column narrow>
                  <Heading size={5} textColor='dark' display='relative'>
                    <Element renderAs='span' textColor='success' style={{ position: 'absolute', left: '-1.25em' }}>✓ </Element>
                    {t('sav:confirmation.title')}
                  </Heading>
                  <p>
                    {t('sav:confirmation.thx')} <br />
                  </p>
                </Columns.Column>
              </Columns>
            </Container>
          </Section>
          :
          <Section size='medium'>
            <Container>
              <Columns centered>
                <Columns.Column size={12} offset={6}>
                  <Heading size={5} mb={5} textColor='dark' textWeight='normal'>
                    SAV - Nous contacter
                  </Heading>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Columns>
                      <Columns.Column size={6}>
                        <FormField label={t('forms:contact.email')} horizontal={false}>
                          <HookFormWrapper
                            name='email'
                            control={ control }
                            icon={ <FontAwesomeIcon icon={faUser} /> }
                            defaultValue={ sessionState.currentUser && sessionState.currentUser.email }
                            placeholder='email...'
                            rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
                            disabled
                          />
                        </FormField>
                        <FormField label={t('forms:sav.subject')} horizontal={false}>
                          <HookFormWrapper
                            name='subject'
                            control={ control }
                            placeholder='Sujet de votre demande...'
                            rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
                          />
                        </FormField>
                        <FormField label={t('forms:sav.message')} horizontal={false}>
                          <HookFormWrapper
                            name='message'
                            control={ control }
                            component='Textarea'
                            style={{ width: '100%', maxWidth: '100%', resize: 'vertical', minHeight: 100 }}
                            placeholder='Sujet de votre demande...'
                            rules={ { required: { value: true, message: t('forms:validations.required_field') } } }
                          />
                        </FormField>
                      </Columns.Column>
                    </Columns>
                    <Form.Field>
                      <Form.Control>
                        <Button
                          type='submit'
                          color='primary'
                        >
                          {t('forms:sav.submit')}
                        </Button>
                      </Form.Control>
                    </Form.Field>

                  </form>
                </Columns.Column>
              </Columns>
            </Container>
          </Section >
      }
    </Layout >
  )
}

export default SavPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`